<template>
    <div class="image-input">
        <label v-if="!imageSrc" class="upload-label">
            <input type="file" @change="onFileChange" />
            Click to upload image
        </label>
        <img v-else :src="imageSrc" alt="Uploaded Image" @click="resetImage" />
    </div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            required: false,
        },
        modelValue: {}
    },
    data() {
        return {
            imageSrc: null,
            value: null,
        };
    },
    emits: ['update:modelValue'],
    methods: {
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.$emit('update:modelValue', file);
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageSrc = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        resetImage() {
            this.imageSrc = null;
        },
    },
    mounted() {
        if (this.modelValue) {
            this.imageSrc = '/storage/img/'+ this.modelValue;
        }
    },
};
</script>

<style scoped>
.image-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
    padding: 20px;
    cursor: pointer;
}

.upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

img {
    max-width: 100%;
    max-height: 100%;
}
</style>
