<script >
// import {GridStack} from "gridstack";
// import 'gridstack/dist/gridstack.min.css';
// import 'gridstack/dist/gridstack-extra.min.css';
import GridItem from "@/Pages/Components/Elements/GridItem.vue";
export default {
    props: {
        _grids: Object,
    },
    components: {
        GridItem
    },
    setup() {
        let grid = null
        return {grid}
    },
    mounted() {
        // this.grids = GridStack.initAll({
        //     removable: false,
        //     disableOneColumnMode: false, // Enable responsive behavior
        //     acceptWidgets: false,
        //     removeTimeout: 2000,
        //     animate: true,
        //     column: 8,
        //     staticGrid: true,
        //
        //     columnOpts: {
        //         layout: 'compact',
        //
        //         columnMax: 10,
        //         breakpointForWindow: true, // Enable responsive behavior
        //         breakpoints: [ // Define breakpoints for responsive design
        //             {w: 1000, c: 3},    // >= 768px
        //             {w: 1240, c: 4},    // >= 992px
        //             {w: 1700, c: 6},  // >= 1200px
        //             {w: 1900, c: 10},
        //         ],
        //     }
        // });
        //
        // if (this._grids['video']) {
        //     this.grids[0].load(JSON.parse(this._grids['video']));
        // }
        //
        // if (this._grids['faq']) {
        //     this.grids[1].load(JSON.parse(this._grids['faq']));
        // }
        //
        // document.querySelectorAll('div.tile').forEach((el) => {
        //     el.attributes.href = '/redirect_to_page/' + el.attributes.id.value;
        //
        //     el.addEventListener('click', () => {
        //         let href = el.attributes.href;
        //
        //         console.log(href)
        //
        //         this.$inertia.visit(href);
        //
        //         console.log(id)
        //     });
        // });
        //
        // document.querySelectorAll('p[contenteditable=true]').forEach((el) => {
        //     console.log(el)
        //     el.setAttribute('contenteditable', false);
        // });
    },
}
</script>

<template>
    <div class="custom_css">
        <div>
            <h1>Videos</h1>

            <div class="flex gap-4 p-4 video_grid flex-wrap">
                <template v-for="item in _grids['video']">
                    <GridItem :item="item"/>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.custom_css {
    max-height: calc(100vh - 120px) !important;
    max-width: calc(100vw - 207px) !important;
    overflow-y: scroll;
}

h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.video_grid {

}
</style>
