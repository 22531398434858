<script>
import {Switch, SwitchDescription, SwitchGroup, SwitchLabel} from '@headlessui/vue'
import {useForm, Link} from "@inertiajs/vue3";

export default {
    props: {
        _user: {
            type: Object,
            required: true,
            default: () => ({
                name: null,
            })
        },
        permissions: {
            type: Array,
            required: true,
            default: () => ([])
        }
    },
    components: {
        Switch,
        SwitchDescription,
        SwitchGroup,
        SwitchLabel,
        Link
    },
    setup(props) {
        let form = useForm({});

        props.permissions.forEach(permission => {
            form[permission.value] = props._user.permissions.map(
                permission => permission.value
            ).includes(permission.value)
        })

        return {
            form
        }
    },
    methods: {
        async updatePermissions() {
            // Get the permission values
            const permissionValues = this.permissions.map(permission => permission.value);

            // Filter the form data to only include keys that match the permission values and their value is true
            const formData = Object.keys(this.form)
                .filter(key => permissionValues.includes(key) && this.form[key])
                .reduce((obj, key) => {
                    obj[key] = this.form[key];
                    return obj;
                }, {});

            await this.$inertia.put('/user/' + this._user.id + '/permissions', {
                permissions: formData
            }, {
                onSuccess: () => {
                    this.reloadPermissions()
                }
            });
        },

        reloadPermissions() {
            this.permissions.forEach(permission => {
                this.form[permission.value] = this._user.permissions.map(
                    permission => permission.value
                ).includes(permission.value)
            })
        }
    },
    watch: {
        form: {
            handler: 'updatePermissions',
            deep: true
        }
    }
}
</script>

<template>
    <div class="pl-4">
        <h1 class="text-2xl font-semibold text-gray-900">Gebruiker: {{ _user.name }}</h1>
        <Link href='/users' class="mt-2 text-sm text-indigo-600 underline">Terug naar gebruikers</Link>
        <!--        <p class="mt-2 text-sm text-red-500">Let op: Dit zijn locale gebruikers, om een account volledig te verwijden-->
        <!--            druk op de 3 puntjes en "ga naar gebruikers profiel"</p>-->
    </div>

    <div class="p-8">
        <SwitchGroup v-for="permission in permissions" as="div" class="flex items-center justify-between p-4">
            <span class="flex flex-grow flex-col">
                <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>{{permission.label}}</SwitchLabel>
                <SwitchDescription as="span" class="text-sm text-gray-500">{{permission.description}}</SwitchDescription>
            </span>
            <Switch v-model="form[permission.value]" :class="[form[permission.value] ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                <span :class="[form[permission.value] ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" aria-hidden="true"/>
            </Switch>
        </SwitchGroup>
    </div>
</template>
<style scoped>

</style>
