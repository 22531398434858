<template>
    <div class="custom_css">
        <div>
            <h1>Videos</h1>

            <div class="flex flex-wrap gap-4 p-4 video_grid">
                <template v-for="item in _grids['video']">
                    <GridItemEditable :item="item" v-on:click="openModal(item)"/>
                </template>
                <GridItemEmpty v-on:click="newItem"></GridItemEmpty>
            </div>
        </div>

        <Modal v-cloak :show="modal">
            <div class="flex justify-between">
                <h1>{{ selected_item.label }}</h1>
                <svg v-if="selected_item.id" class="cursor-pointer" v-on:click="deleteItem" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M3 6h18v2H3V6zm3 3h12v12c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V9zm7-7V0H9v2H4v2h16V2h-5z"/>
                </svg>
            </div>

            <div class="flex flex-col gap-4">
                <ImageInput v-model="selected_item.icon"></ImageInput>
                <TextInput v-model="selected_item.label" label="Titel" placeholder="Titel"></TextInput>

                <CheckboxGroup>
                    <Checkbox v-model="selected_item.is_video" description="Selecteer dit om een video te laden, laat dit staan om een pagina te openen"
                              label="Video"></Checkbox>
                </CheckboxGroup>

                <TextInput v-if="selected_item.is_video" v-model="selected_item.video_href" label="Video Link"
                           placeholder="example.com"></TextInput>

                <div v-else>
                    <Label class="mb-2 block text-sm font-medium leading-6 text-gray-900">Page</Label>
                    <select v-model="selected_item.redirect_to_page_id"
                            class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option v-for="page in pages" :value="page.id">
                            {{ page.label }}
                        </option>
                    </select>
                </div>
            </div>

            <ModalButtonGroup>
                <ModalButton :outline="true" label="Annuleren" v-on:click="cancel"></ModalButton>
                <ModalButton label="Opslaan" v-on:click="save"></ModalButton>
            </ModalButtonGroup>
        </Modal>
    </div>
</template>

<style scoped>
.custom_css {
    max-height: calc(100vh - 120px) !important;
    max-width: calc(100vw - 207px) !important;
    overflow-y: scroll;
}

h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}
</style>

<script>
import {computed, ref} from 'vue';

import GridItemEditable from "@/Pages/Components/Elements/GridItemEditable.vue";
import GridItemEmpty from "@/Pages/Components/Elements/GridItemEmpty.vue";
import EmptyGridAction from "@/Pages/Components/Elements/EmptyGridAction.vue";
import Modal from "@/Pages/Components/Elements/Modal.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import ModalButtonGroup from "@/Pages/Components/Elements/ModalButtonGroup.vue";
import ModalButton from "@/Pages/Components/Elements/ModalButton.vue";
import TextInput from "@/Pages/Components/Forms/TextInput.vue";
import ImageInput from "@/Pages/Components/Elements/ImageInput.vue";
import CheckboxGroup from "@/Pages/Components/Forms/CheckboxGroup.vue";
import Checkbox from "@/Pages/Components/Forms/Checkbox.vue";

let page = usePage();

export default {
    components: {
        Checkbox,
        CheckboxGroup,
        TextInput, ModalButton, ModalButtonGroup, Modal, EmptyGridAction, GridItemEditable, ImageInput, GridItemEmpty
    },
    props: {
        _grids: Object,
        page_id: {}
    },
    setup() {
        let grid = null;
        let modal = ref(false);

        let selected_item = useForm({
            id: null,
            label: '',
            href: '',
            video_href: '',
            is_video: false,
            icon: '',
            icon_href: '',
            redirect_to_page_id: null,
            belongs_to_page_id: null,
        })

        let pages = computed(() => page.props.pages);

        return {grid, selected_item, modal, pages}
    },
    methods: {
        openModal(item) {
            this.selected_item.id = item.id;
            this.selected_item.label = item.label;
            this.selected_item.href = item.href;
            this.selected_item.icon = item.icon;
            this.selected_item.video_href = item.video_href;
            this.selected_item.is_video = item.is_video;
            this.selected_item.redirect_to_page_id = item.redirect_to_page_id;
            this.selected_item.belongs_to_page_id = this.page_id
            this.modal = true;
        },
        newItem() {
            this.selected_item.reset()
            this.selected_item.belongs_to_page_id = this.page_id
            this.modal = true
        },
        save() {
            this.selected_item.post('/video', {
                preserveScroll: true,
                preserveState: false,
                onSuccess: () => {
                    this.modal = false;
                    this.selected_item.defaults();
                }
            });
        },
        cancel() {
            this.selected_item.defaults();
            this.modal = false;
        },
        deleteItem() {
            if (!this.selected_item.id) {
                return;
            }

            if (!confirm('Weet je zeker dat je dit item wilt verwijderen?')) {
                return;
            }

            this.selected_item.delete('/video/' + this.selected_item.id, {
                preserveScroll: true,
                preserveState: false,
                onSuccess: () => {
                    this.modal = false;
                    this.selected_item.defaults();
                }
            });
        }
    }
}
</script>
