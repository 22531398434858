<script>
import TextInput from "@/Pages/Components/Forms/TextInput.vue";
import Modal from "@/Pages/Components/Elements/Modal.vue";
import ModalButton from "@/Pages/Components/Elements/ModalButton.vue";
import {ClassicEditor} from "@ckeditor/ckeditor5-editor-classic";
import {Alignment} from "@ckeditor/ckeditor5-alignment";
import {Autoformat} from "@ckeditor/ckeditor5-autoformat";
import {BlockQuote} from "@ckeditor/ckeditor5-block-quote";
import {CodeBlock} from '@ckeditor/ckeditor5-code-block';
import {FindAndReplace} from "@ckeditor/ckeditor5-find-and-replace";
import {Font, FontBackgroundColor, FontColor, FontFamily} from "@ckeditor/ckeditor5-font";
import {Highlight} from "@ckeditor/ckeditor5-highlight";
import {
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar
} from "@ckeditor/ckeditor5-table";
import {Essentials} from '@ckeditor/ckeditor5-essentials';
import {Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline} from '@ckeditor/ckeditor5-basic-styles';
import {AutoLink, Link, LinkImage} from '@ckeditor/ckeditor5-link';
import {Paragraph} from '@ckeditor/ckeditor5-paragraph';
import {Heading} from "@ckeditor/ckeditor5-heading";
import {
    AutoImage,
    Image,
    ImageCaption,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload
} from "@ckeditor/ckeditor5-image";
import {Indent, IndentBlock} from "@ckeditor/ckeditor5-indent";
import {List, ListProperties, TodoList} from "@ckeditor/ckeditor5-list";
import {MediaEmbed} from "@ckeditor/ckeditor5-media-embed";
import {RemoveFormat} from "@ckeditor/ckeditor5-remove-format";
import {SpecialCharacters} from "@ckeditor/ckeditor5-special-characters";
import {Typing} from "@ckeditor/ckeditor5-typing";
import {Base64UploadAdapter} from "@ckeditor/ckeditor5-upload";
import {PasteFromOffice} from "@ckeditor/ckeditor5-paste-from-office";
import {ShowBlocks} from "@ckeditor/ckeditor5-show-blocks";
import PrimaryButton from "@/Pages/Components/Elements/PrimaryButton.vue";

export default {
    components: {PrimaryButton, ModalButton, Modal, TextInput},
    props: {
        html: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            editorData: this.html,
            editorConfig: {
                plugins: [
                    Alignment,
                    Autoformat,
                    BlockQuote,
                    FindAndReplace,
                    Font, FontBackgroundColor, FontColor, FontFamily,
                    Highlight,
                    Table, TableToolbar, TableCellProperties, TableProperties, TableCaption, TableColumnResize,
                    Essentials,
                    Bold, Italic, Underline, Strikethrough, Code, CodeBlock, Subscript, Superscript,
                    Link, AutoLink,
                    Paragraph,
                    Heading,
                    Indent, IndentBlock,
                    List, ListProperties, TodoList,
                    MediaEmbed,
                    RemoveFormat,
                    PasteFromOffice,
                    SpecialCharacters,
                    ShowBlocks,
                    Typing,
                    Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage, ImageUpload, ImageInsertViaUrl, AutoImage, Base64UploadAdapter, ImageInsert
                ],
                config: {
                    height: '100%',
                },
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        'toggleImageCaption',
                        '|',
                        'imageStyle:inline',
                        'imageStyle:wrapText',
                        'imageStyle:breakText',
                        'imageStyle:side',
                        '|',
                        'linkImage',
                        'imageResize',
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                },
                editorDisabled: true,
                heading: {
                    options: [
                        {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                        {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
                        {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
                        {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
                        {model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
                        {model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'},
                        {model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6'}
                    ]
                },
            }
        }
    },
}


</script>

<template>
    <div class="prose" style="max-width: 100%; height: calc(100vh - 8px); max-height: calc(100vh - 120px); overflow-y: scroll;">
        <ckeditor v-model="editorData" :config="editorConfig" :disabled="true"   :editor="editor" ></ckeditor>
    </div>

</template>

<style scoped>

</style>
