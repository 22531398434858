<script setup>

</script>

<template>
    <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <slot></slot>
    </div>
</template>

<style scoped>

</style>
