<script>
import Editor from "@/Pages/Components/Elements/Editor.vue";

export default {
    components: {Editor},
    props: {
        video: {
            type: String,
            required: true
        }
    },
    setup(props) {
        let html = '<figure className="media"><oembed url="'+props.video+'"></oembed></figure>'
        return {html}
    }
}
</script>

<template>
    <Editor :html="html"></Editor>
</template>
