<template>
    <div class="flex justify-between items-center w-11/12 ">
        <div class="pl-4">
            <h1 class="text-2xl font-semibold text-gray-900">Gebruikers</h1>
            <p class="mt-2 text-sm text-red-500">Let op: Dit zijn locale gebruikers, om een account volledig te verwijden druk op de 3 puntjes en "ga naar gebruikers profiel"</p>
        </div>
        <a class="mt-4 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded" :href="auth_host + '/users/create'" target="_blank">Nieuwe
            gebruiker
        </a>
    </div>
    <div style="max-height: calc(100vh - 200px); height: 100%; overflow-y: auto">
        <ul class="divide-y divide-gray-100 pl-8" role="list">
            <li v-for="user in users" :key="user.id"
                class="flex items-center justify-between w-11/12 py-4 gap-x-6">
                <div class="flex gap-4">
                    <img :src="'https://ui-avatars.com/api/?name='+user.name+'&size=64&backround=ccc&color=000'"
                         alt="" class="h-12 w-12 rounded-full"/>
                    <div class="min-w-0">
                        <div class="flex items-start gap-x-3">
                            <p class="text-sm font-semibold leading-6 text-gray-900">{{ user.name }}</p>
                            <p v-if="user.email === authed_user.email" :class=" 'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'">Me</p>
                            <p v-if="user.can_be_deleted === 0" :class=" 'text-red-600 bg-red-50 ring-red-500/10 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'">Admin</p>
                            <p :class="[statuses[user.status], 'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset']">
                                {{ user.status === 1 ? 'Active' : 'Disabled'}}</p>
                        </div>
                        <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p class="truncate">Aangemaakt op {{ new Date(user.created_at).toLocaleDateString('nl') }}</p>
                        </div>
                    </div>
                </div>

                <div class="flex flex-none items-center gap-x-4">
                    <a :href="'/user/'+ user.id + '/permissions'" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Rechten</a>
                    <Menu as="div" class="relative flex-none">
                        <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                            <span class="sr-only">Open options</span>
                            <EllipsisVerticalIcon aria-hidden="true" class="h-5 w-5"/>
                        </MenuButton>
                        <transition enter-active-class="transition ease-out duration-100"
                                    enter-from-class="transform opacity-0 scale-95"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75"
                                    leave-from-class="transform opacity-100 scale-100"
                                    leave-to-class="transform opacity-0 scale-95">
                            <MenuItems
                                class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <MenuItem v-slot="{ active }">
                                    <a :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-indigo-600']" target="_blank" :href="auth_host + '/user/'+ user.id + '/edit/'">Ga naar gebruikers profiel</a>
                                </MenuItem>
                                <MenuItem v-if="user.can_be_deleted" v-slot="{ active }">
                                    <Link v-if="user.status === 1" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-red-600']" method="PUT" :href="'/user/'+user.id + '/disable'">Disable</Link>
                                    <Link v-else-if="user.status === 0" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-green-600']" method="PUT" :href="'/user/'+user.id + '/enable'">Enable</Link>
                                </MenuItem>
                                <MenuItem v-if="user.can_be_deleted" v-slot="{ active }">
                                    <Link :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-red-700']" method="DELETE" :href="'/user/'+user.id">Delete</Link>
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </li>
        </ul>
    </div>

</template>

<script>
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {EllipsisVerticalIcon} from '@heroicons/vue/20/solid'
import {usePage, Link} from "@inertiajs/vue3";
import {computed} from "vue";

const page = usePage();

export default {
    props: {
        users: {
            type: Object,
            required: true,
            default: () => ({
                id: null,
                name: null,
                status: null,
                can_be_deleted: null,
                email: null,
                created_at: null
            }),
        },
    },
    components: {
        Menu, MenuButton, MenuItem, MenuItems, EllipsisVerticalIcon, Link
    },
    setup() {
        let auth_host = computed(() => page.props.env.auth_host);
        let authed_user = computed(() => page.props.user);

        const statuses = {
            1: 'text-green-700 bg-green-50 ring-green-600/20',
            0: 'text-red-600 bg-red-50 ring-red-500/10',
        }

        return {
            auth_host,
            authed_user,
            statuses
        }
    },
}
</script>
