<script>
export default {
    props: {
        label: {
            type: String,
            default: 'new button',
            required: true
        },
        outline: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    emits: ['click'],
}
</script>

<template>
    <button type="button" v-if="outline" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" v-text="label" @click="$emit('click', $event.target)"></button>
    <button type="button" v-else class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-text="label" @click="$emit('click', $event.target)" ></button>
</template>

<style scoped>

</style>
