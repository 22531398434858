<script>
import Table from "@/Pages/Components/Elements/Table.vue";
import {Link, useForm, usePage} from "@inertiajs/vue3";
import TextInput from "@/Pages/Components/Forms/TextInput.vue";
import Modal from "@/Pages/Components/Elements/Modal.vue";
import ModalButtonGroup from "@/Pages/Components/Elements/ModalButtonGroup.vue";
import ModalButton from "@/Pages/Components/Elements/ModalButton.vue";
import CheckboxGroup from "@/Pages/Components/Forms/CheckboxGroup.vue";
import Checkbox from "@/Pages/Components/Forms/Checkbox.vue";
import {computed} from "vue";

const page = usePage();

export  default {
    components: {Table, Link, TextInput,
        Modal,
        ModalButtonGroup,
        ModalButton,
        CheckboxGroup,
        Checkbox},
    props: {
        errors: {
            type: Object,
            required: false
        },
        pages: {
            type: Array,
            required: true
        }
    },
    setup() {

        let form = useForm({
            label: '',
            href: '',
            vue_component: '',
            only_for_dyade_employees: false,
            hidden: false,
            grid: false
        });

        let _user_permissions = computed(() => page.props.user_permissions);

        return {
            form,
            user_permissions: _user_permissions
        }
    },
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        save() {
            // this.showModal = false;
            this.form.post('/page/create', {
                preserveState: true,
                onSuccess: () => {
                    this.showModal = false;
                }
            });
        }
    }
}
</script>

<template>
    <div>
        <h1 class="text-2xl font-semibold text-gray-900">Pages</h1>
        <p class="mt-2 text-sm text-gray-500">This is a list of all the pages on your website.</p>
        <button v-if="user_permissions.includes('create_pages')" class="mt-4 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded" @click="showModal = true">Create new page</button>
    </div>

    <div class="p-4 overflow-scroll max-h-[900px]">
        <Table :headers="['Name', 'Href', 'dyade only', 'hidden', 'grid', 'actions']">
            <tr v-for="page in pages" :key="page.id">
                <td class="whitespace-nowrap pl-3.5 text-sm font-medium text-gray-900 py-4 pr-3.5">{{ page.label }}</td>
                <td class="whitespace-nowrap pl-3.5 text-sm font-medium text-gray-900 py-4 pr-3.5">{{ page.href }}</td>
                <td class="whitespace-nowrap pl-3.5 text-sm font-medium text-gray-900 py-4 pr-3.5">{{ page.dyade_only ? 'Yes' : 'No'}}</td>
                <td class="whitespace-nowrap pl-3.5 text-sm font-medium text-gray-900 py-4 pr-3.5">{{ page.hidden ? 'Yes' : 'No'}}</td>
                <td class="whitespace-nowrap pl-3.5 text-sm font-medium text-gray-900 py-4 pr-3.5">{{ page.grid ? 'Yes' : 'No'}}</td>
                <td class="whitespace-nowrap pl-3.5 text-sm font-medium text-gray-900 py-4 pr-3.5 flex gap-4">
                    <Link v-if="user_permissions.includes('edit_pages')" :href="'/page/'+page.href+'/edit'" class="text-indigo-600 hover:text-indigo-900">Edit</Link>
                    <Link v-if="user_permissions.includes('delete_pages')" :href="'/page/'+page.href" method="delete" class="text-indigo-600 hover:text-indigo-900">Delete</Link>
                </td>
            </tr>
        </Table>
    </div>



    <Modal :show="showModal">
        <div class="flex flex-col gap-4">
            <h1>Creating a new page</h1>

            <TextInput v-model="form.label" :validation="errors?.label?.length > 0" :validation_message="errors.label" label="Label"/>
            <TextInput v-model="form.href" :validation="errors?.href?.length > 0" :validation_message="errors.href" label="Href"/>

            <CheckboxGroup>
                <Checkbox v-model="form.only_for_dyade_employees" label="Only for dyade employees"
                          description="Only show this page to logged in dyade employees."></Checkbox>
                <Checkbox v-model="form.hidden" label="Hidden"
                          description="Hide this page from both the url and navigation."></Checkbox>
                <Checkbox v-model="form.grid" label="Grid"
                          description="Make this page a Grid layouy (for homepages of a program)"></Checkbox>
            </CheckboxGroup>

            <ModalButtonGroup>
                <ModalButton label="Cancel" :outline="true" v-on:click="showModal = false"></ModalButton>
                <ModalButton label="Save" v-if="user_permissions.includes('create_pages')" v-on:click="save"></ModalButton>
            </ModalButtonGroup>
        </div>
    </Modal>
</template>

<style scoped>

</style>
