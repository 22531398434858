<template>
    <div>
        <div class="mt-4 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                            <tr>
                                <th v-for="header in headers" :key="header" scope="col" class="px-3.5 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ header }}
                                </th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                            <slot></slot>
<!--                            <tr v-for="person in people" :key="person.email">-->
<!--                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ person.name }}</td>-->
<!--                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.title }}</td>-->
<!--                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.email }}</td>-->
<!--                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.role }}</td>-->
<!--                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">-->
<!--                                    <a href="#" class="text-indigo-600 hover:text-indigo-900"-->
<!--                                    >Edit<span class="sr-only">, {{ person.name }}</span></a-->
<!--                                    >-->
<!--                                </td>-->
<!--                            </tr>-->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
    }
}
</script>

<!--<script setup>-->
<!--import {DialogDescription} from "@headlessui/vue";-->

<!--const people = [-->
<!--    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },-->
<!--    // More people...-->
<!--]-->
<!--</script>-->
