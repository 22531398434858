<template>
    <div class="calendar-card">
        <div class="calendar-header">
            <div class="calendar-icon">
                <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 6v6m0 0v6m0-6h6m-6 0H6" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                </svg>
            </div>
        </div>
        <div class="calendar-title">Nieuw item</div>
    </div>
</template>

<script>
export default {
    name: 'GridItem',
    props: {
        item: Object,
    },
}
</script>

<style scoped>
.calendar-card {
    width: 180px;
    height: 180px;
    border-radius: 8px;
    border: lightgray 2px dashed;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    position: relative;
    cursor: pointer;
}

.calendar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.calendar-icon {
    width: 64px;
    height: 64px;
    margin-top: 30px;
}

.edit-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.calendar-title {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
}
</style>
