<script>
export default {
    props: {
        id: {
            type: String,
            default: Math.random().toString(36).substring(3),
            required: false
        },
        modelValue: {},
        type: {
            type: String,
            default: 'text',
            required: false,
            options: ['text', 'email', 'password', 'number', 'tel', 'url']
        },
        label: {
            type: String,
            default: 'new input',
            required: false
        },
        help_text: {
            type: String,
            default: '',
            required: false
        },
        placeholder: {
            type: String,
            default: '',
            required: false
        },
        validation: {
            type: Boolean,
            default: false,
            required: false
        },
        validation_message: {
            type: String,
            default: '',
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        readonly: {
            type: Boolean,
            default: false,
            required: false
        },
        autofocus: {
            type: Boolean,
            default: false,
            required: false
        },
        required: {
            type: Boolean,
            default: false,
            required: false
        },
        no_label: {
            type: Boolean,
            default: false,
            required: false
        },
    },
}
</script>

<template>
    <div>
        <label v-if="!no_label" :for="id" v-text="label"></label>
        <label v-if="no_label" :for="id" v-text="label" class="sr-only"></label>
        <div class="mt-2">
            <input :type="type" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :name="label" :id="id" :placeholder="placeholder" :disabled="disabled"
                   :readonly="readonly" :class="[validation === true ? 'invalid' : '']"/>
        </div>
        <p v-if="validation === true" class="validation" id="{{id}}-error"
           v-text="validation_message"></p>
        <p v-else-if="help_text" class="help_text" id="{{id}}-help" v-text="help_text"></p>
    </div>
</template>

<style scoped>
label {
    @apply block text-sm font-medium leading-6 text-gray-900;
}

input {
    @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6;
}

input:disabled {
    @apply bg-gray-100 text-gray-500;
}

input:read-only {
    @apply bg-gray-100 text-gray-500;
}

input:focus {
    @apply ring-indigo-600;
}

input::placeholder {
    @apply text-gray-400;
}

input.invalid {
    @apply ring-2 ring-red-500;
}

p.validation {
    @apply mt-2 text-sm text-red-600;
}

p.help_text {
    @apply mt-2 text-sm text-gray-500;
}

</style>
