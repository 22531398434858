<script setup>

</script>

<template>
    <div class="">
        Uw partner in Finance & HR
    </div>
</template>

<style scoped>
div {
    width: 100%;
    background: #b90834;
    color: white;
    font-size: 1.5em;
    padding: 0.25em 0.25em 0.25em 1em;
}
</style>
