<script>
import Editor from "@/Pages/Components/Elements/Editor.vue";

export default {
    components: {Editor},
    props: {
        page: {
            type: Object,
            required: true
        }
    },
}
</script>


<template >
    <div v-html="page.video_grid"></div>
    <Editor  :html="page.html">

    </Editor>
<!--    <div class="" style="overflow-y:scroll" v-html="page.html"></div>-->

</template>

<style>

</style>
