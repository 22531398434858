<script>
import {Disclosure, DisclosureButton, DisclosurePanel, Tab} from '@headlessui/vue'
import {ChevronRightIcon} from '@heroicons/vue/20/solid'
import {computed, ref} from "vue";
import {Link, router, useForm, usePage} from "@inertiajs/vue3";

import Header from "@/Pages/Components/Layout/Header.vue";
import Footer from "@/Pages/Components/Layout/Footer.vue";
import Modal from "@/Pages/Components/Elements/Modal.vue";
import ModalButtonGroup from "@/Pages/Components/Elements/ModalButtonGroup.vue";
import ModalButton from "@/Pages/Components/Elements/ModalButton.vue";
import TextInput from "@/Pages/Components/Forms/TextInput.vue";
import PrimaryButton from "@/Pages/Components/Elements/PrimaryButton.vue";
import Table from "@/Pages/Components/Elements/Table.vue";
import Notification from "@/Pages/Components/Elements/Notification.vue";

let page = usePage();

export default {
    components: {
        Notification,
        Table,
        Tab,
        ModalButton,
        ModalButtonGroup,
        PrimaryButton,
        TextInput,
        Modal,
        Header,
        Footer,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        ChevronRightIcon,
        Link
    },
    setup(props) {
        let nav = computed(() => page.props.nav.items);
        let user = computed(() => page.props.user);
        let _user_permissions = computed(() => page.props.user_permissions);
        let pages = computed(() => page.props.pages);


        let navForm = useForm({
            id: null,
            label: null,
            page_id: null,
            sub_items: []
        });

        let newItem = ref({
            id: null,
            label: '',
            page_id: ''
        });

        // const user_permissions = _user_permissions.map(
        //     permission => permission.value
        // )

        return {
            nav: nav,
            navForm: navForm,
            newItem: newItem,
            user: user,
            pages: pages,
            user_permissions: _user_permissions
        }
    },
    data() {
        return {
            editing: false,
            showModal: false
        }
    },
    methods: {
        openModal(item) {
            this.showModal = true;
            this.navForm.label = item.label;
            this.navForm.page_id = item.page_id;
            this.navForm.sub_items = item.sub_items;
            this.navForm.id = item.id;
        },
        newSubItem() {
            const newItem = {
                id: null,
                label: this.newItem.label,
                page_id: this.newItem.page_id
            };

            this.navForm.sub_items = [...this.navForm.sub_items, newItem];

            // Clear the input fields
            this.newItem.label = '';
            this.newItem.page_id = '';
        },
        save() {
            console.log(this.navForm);
            let data = new FormData();

            data.append('label', this.navForm.label);
            data.append('page_id', this.navForm.page_id);
            data.append('sub_items', JSON.stringify(this.navForm.sub_items));

            if (this.navForm.id === null) {
                this.$inertia.post(`/nav`, data);
            } else {
                this.$inertia.post(`/nav/${this.navForm.id}`, data);
            }

            this.showModal = false;
        },
        deleteItem(item) {
            this.navForm.sub_items.splice(this.navForm.sub_items.indexOf(item), 1)
        },
        closeAndRollback() {
            this.showModal = false;
            this.navForm.label = null;
            this.navForm.page_id = null;
            this.navForm.sub_items = [];
            this.navForm.id = null;

            router.reload({only: ['nav.items']})

            this.nav.sub_items = [];
        },
        newItem() {
            this.navForm.label = '';
            this.navForm.page_id = '';
            this.navForm.sub_items = [];
            this.navForm.id = null;
            this.showModal = true;
        },
        openPages() {
            this.$inertia.visit('/pages');
        }
    }
}

</script>

<template>
    <div class="flex flex-col justify-between h-full">
        <Header></Header>
        <div class="flex" style="width: 100%;  height: 100%">
            <div
                class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 h-full w-96 max-w-64">
                <div class="h-8"></div>
                <nav class="flex flex-1 flex-col">
                    <ul class="flex flex-1 flex-col gap-y-7" role="list">
                        <li>
                            <ul class="-mx-2 space-y-1" role="list">
                                <li v-for="item in nav" :key="item.label">
                                    <template v-if="!editing || !user_permissions.includes('navigation')">
                                        <Link v-if="item.sub_items.length === 0" :href="'/page/'+item.page.href"
                                              class="'hover:bg-gray-50 block rounded-md py-2 pr-2 pl-10 text-sm leading-6 font-semibold text-gray-700'">
                                            {{ item.label }}
                                        </Link>
                                        <Disclosure v-else v-slot="{ open }" as="div">
                                            <DisclosureButton
                                                :class="[item.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700']">
                                                <ChevronRightIcon
                                                    :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'h-5 w-5 shrink-0']"
                                                    aria-hidden="true"/>
                                                {{ item.label }}
                                            </DisclosureButton>
                                            <DisclosurePanel as="ul" class="mt-1 px-2">
                                                <li v-for="subItem in item.sub_items" :key="subItem.label">
                                                    <Link
                                                        :class="[subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700']"
                                                        :href="'/page/'+subItem.page.href"
                                                        :page_id="subItem.page_id">
                                                        {{ subItem.label }}
                                                    </Link>
                                                </li>
                                            </DisclosurePanel>
                                        </Disclosure>
                                    </template>
                                    <template v-else>
                                        <a class="hover:bg-gray-50 block rounded-md py-2 pr-2 pl-10 text-sm leading-6 font-semibold text-gray-700 cursor-pointer"
                                           v-on:click="openModal(item)">
                                            {{ item.label }} <small v-if="item.sub_items.length > 0"
                                                                    class="float-right">+{{ item.sub_items.length }}
                                            subitem{{ item.sub_items.length > 1 ? 's' : '' }}</small>
                                        </a>
                                    </template>
                                </li>
                                <li v-if="editing && user_permissions.includes('navigation')">
                                    <button
                                        class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-2.5 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        type="button"
                                        v-on:click="newItem">
                                        <span class=" block text-sm font-semibold text-gray-900">Nieuw onderdeel</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li class="-mx-6 mt-auto">
                            <div v-if="editing && (user_permissions.includes('create_pages') || user_permissions.includes('edit_pages') || user_permissions.includes('delete_pages') )" class="px-4">
                                <button
                                    class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-2.5 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    type="button"
                                    v-on:click="openPages">
                                    <span class=" block text-sm font-semibold text-gray-900">Pagina's</span>
                                </button>
                            </div>
                            <div v-if="editing && user_permissions.includes('manage_users')" class="px-4 py-2">
                                <button
                                    class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-2.5 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    type="button"
                                    v-on:click="this.$inertia.visit('/users')">
                                    <span class=" block text-sm font-semibold text-gray-900">Gebruikers</span>
                                </button>
                            </div>
                            <div class="flex items-center justify-between pr-4 ">
                                <a class="flex flex-1 items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                                   href="#">
                                    <span class="sr-only">Your profile</span>
                                    <span aria-hidden="true">{{ user?.name }}</span>
                                </a>
                                <svg v-if="user" class="w-7 h-7 p-0.5 cursor-pointer" fill="none"
                                     stroke="currentColor"
                                     stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                     v-on:click="editing = !editing">
                                    <path
                                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="p-2 w-full " style="max-height: 100vh">
                <slot></slot>
            </div>
        </div>
        <Footer></Footer>
    </div>

    <Modal v-cloak :show="showModal">
        <div class="flex flex-col gap-4">
            <TextInput v-model="navForm.label" label="Label"></TextInput>
            <div>
                <Label class="mb-2 block text-sm font-medium leading-6 text-gray-900">Page</Label>
                <select v-model="navForm.page_id" :disabled="navForm.sub_items.length > 0"
                        class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option v-for="page in pages" :value="page.id">
                        {{ page.label }}
                    </option>
                </select>
                <p v-if="navForm.sub_items.length > 0" class="mt-2 text-sm text-gray-500">This is disabled cause this is
                    a
                    group</p>
            </div>
            <Table :headers="['Label', 'Page', 'Actions']">
                <tr v-for="item in navForm.sub_items">
                    <td class="whitespace-nowrap pl-3.5 text-sm font-medium text-gray-900 py-4 pr-3.5">
                        <TextInput v-model="item.label" :no_label="true" type="text"></TextInput>
                    </td>
                    <td class="whitespace-nowrap pl-3.5 text-sm font-medium text-gray-900 py-4 pr-3.5">
                        <select v-model="item.page_id"
                                class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <option v-for="page in pages" :value="page.id">
                                {{ page.label }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <PrimaryButton v-on:click="deleteItem(item)">Delete</PrimaryButton>
                    </td>
                </tr>
            </Table>

            <PrimaryButton v-on:click="newSubItem">Nieuw sub-item</PrimaryButton>

            <ModalButtonGroup>
                <ModalButton :outline="true" label="Cancel" v-on:click="closeAndRollback"></ModalButton>
                <ModalButton label="Save" v-on:click="save"></ModalButton>
            </ModalButtonGroup>
        </div>
    </Modal>
</template>

<style scoped>
select:disabled {
    @apply bg-gray-100 text-gray-500;
}
</style>
