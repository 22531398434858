<script setup>

</script>

<template>
    <div class="bg-red-500">
        Dyade
    </div>
</template>

<style scoped>
div {
    width: 100%;
    background: #b90834;
    color: white;
    font-size: 1.5em;
    padding: 0.25em 0.25em 0.25em 1em;
}
</style>
