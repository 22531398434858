<script>

export default {
    props: {
        size: {
            type: String,
            default: 'md',
            required: false,
            options: ['xs', 'sm', 'md', 'lg', 'xl']
        },
        color: {
            type: String,
            default: 'indigo',
            required: false,
            options: ['indigo', 'red', 'yellow', 'green', 'blue', 'purple', 'pink']
        },
        text: {
            type: String,
            default: 'Button text',
            required: false
        }
    }

}

</script>

<template>
    <button type="button" v-if="size === 'xs'" class="1 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"><slot></slot></button>
    <button type="button" v-else-if="size === 'sm'" class="2 rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"><slot></slot></button>
    <button type="button" v-else-if="size === 'md'" class="3 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"><slot></slot></button>
    <button type="button" v-else-if="size === 'lg'" class="4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"><slot></slot></button>
    <button type="button" v-else-if="size === 'xl'" class="5 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"><slot></slot></button>
</template>

<style scoped>

</style>
