<template>
    <div class="calendar-card" @click="clicked">
        <!--        <div class="edit-icon" @click.prevent="console.log('test')">-->
        <!--            <svg class="w-7 h-7 p-0.5 cursor-pointer" fill="none"-->
        <!--                 stroke="currentColor"-->
        <!--                 stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
        <!--                <path-->
        <!--                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"-->
        <!--                    stroke-linecap="round"-->
        <!--                    stroke-linejoin="round"/>-->
        <!--            </svg>-->
        <!--        </div>-->
        <div class="calendar-header">
            <div class="calendar-icon">
                <img :src="'/storage/img/'+item.icon" alt="Icon">
            </div>
        </div>
        <div class="calendar-title">{{ item.label }}</div>
    </div>
</template>

<script>
export default {
    name: 'GridItem',
    props: {
        item: Object,
    },
    methods: {
        clicked() {
            console.log(this.item)

            if (this.item.is_video) {
                this.$inertia.visit('/video/' + this.item.id);
            } else if (this.item.is_page) {
                //
            } else if (this.item.is_external) {
                //
            } else if (this.item.redirect_to_page_id) {
                this.$inertia.visit('/page/id/'+this.item.redirect_to_page_id);
            }
        }
    }
}
</script>

<style scoped>
.calendar-card {
    width: 180px;
    height: 180px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    position: relative;
    cursor: pointer;
}

.calendar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.calendar-icon {
    width: 64px;
    height: 64px;
    margin-top: 30px;
}

.edit-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.calendar-title {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    padding: 2px

}
</style>
