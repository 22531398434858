<script>
export default {
    props: {
        id: {
            type: String,
            required: false
        },
        label: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: false
        },
        modelValue: {},
    },
}
</script>

<template>
    <div class="relative flex items-start">
        <div class="flex h-6 items-center">
            <input :id="id" :value="modelValue" :checked="modelValue" @input="$emit('update:modelValue', $event.target.checked)"
                   aria-describedby="comments-description" :name="id" type="checkbox"
                   class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
        </div>
        <div class="ml-3 text-sm leading-6">
            <label :for="id" class="font-medium text-gray-900">{{ label }}</label>
            <p v-if="description?.length > 0" :id="id + '-description'" class="text-gray-500">{{ description }}</p>
        </div>
    </div>
</template>
