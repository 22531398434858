import {createApp, h} from 'vue'
import {createInertiaApp} from '@inertiajs/vue3'
import Layout from './Pages/Components/Layout/Layout.vue'
import CKEditor from '@ckeditor/ckeditor5-vue';
import * as Sentry from "@sentry/vue";
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from 'gridstack';


createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true})
        let page = pages[`./Pages/${name}.vue`];
        page.default.layout = Layout;
        return page;
    },
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})

        Sentry.init({
            app,
            dsn: "https://cf3c0854095e213f53d0891c7fcd1c9d@o4506977134182400.ingest.us.sentry.io/4506977189625856",
            integrations: [
                Sentry.browserProfilingIntegration(),
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        app.use(plugin)
            .use(CKEditor)
            .mount(el)
    },
    progress: {
        delay: 250,
        color: '#1c31b9',
        includeCSS: true,
        showSpinner: true,
    }
});



